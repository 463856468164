import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Container, HeaderWrapper } from '@components/global';
import productos from '../../datos/Productos';
import { Instagram } from '@styled-icons/boxicons-logos';
import './selector.css';

const ProductoStyle = styled.div`
  padding: 0.25em;
  margin: 0.5em;
  display: flex;
  border: solid 1px #333;
  align-items: center;
  border-radius: 4px;
  box-shadow: 2px 2px #333;
  background-color: white;
  color: #333;
  cursor: pointer;
  font-family: sans;
  font-size: 0.9em;
`;
const Imagen = styled.div`
  padding: 0.5em;
`;
const Cuerpo = styled.div`
  padding: 0.5em;
`;
const SectionTitle = styled.div`
  padding: 0.5em;
  font-size: 1.25em;
  font-family: sans;
  color: white;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -2px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-family: 'Prata', serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  text-shadow: rgb(0, 0, 0) 2px 2px 0px;
  -moz-tab-size: 4;
`;
const Titulo = styled.div`
  font-size: 1.25em;
  font-weight: bold;
`;
const Bajada = styled.div`
  font-size: 0.75em;
  margin-bottom: 1em;
`;

const Button = styled.button`
  font-family: sans;
  font-size: 0.75em;
  background-color: #ccc;
  padding: 0.75em 2em;
`;

const FooterIcon = styled.a`
  color: white;
  margin-right: 0.5em;
  font-weight: normal;
  white-space: nowrap;
`;

// eslint-disable-next-line react/prop-types
const Producto = ({ producto: { id, nombre, bajada, slug } }) => (
  <Link to="/galeria" state={{ producto: id }}>
    <ProductoStyle key={id} to="/galeria">
      <Imagen>
        <img src={`/images/botones/${slug}.jpg`} />
      </Imagen>
      <Cuerpo>
        <Titulo>{nombre}</Titulo>
        <Bajada>{bajada}.</Bajada>
        <Button>Explorar</Button>
      </Cuerpo>
    </ProductoStyle>
  </Link>
);

const SelectorTipoProducto = () => (
  <HeaderWrapper>
    <Container>
      <SectionTitle>Explore soluciones</SectionTitle>{' '}
      {productos.map(p => (
        <Producto producto={p} key={p.id} />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2em',
          // fontFamily: 'sans',
          // backgroundColor: 'red',
          borderRadius: '4px 4px',
          padding: '0.25em',
        }}
      >
        <FooterIcon href="https://www.instagram.com/pasteneycompania">
          <Instagram style={{ height: 36 }} /> Catálogo en Instagram
        </FooterIcon>
      </div>
    </Container>
  </HeaderWrapper>
);

export default SelectorTipoProducto;
