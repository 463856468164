import React from 'react';
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import FooterAlt from '@sections/FooterAlt';
import SelectorTipoProducto from '../components/galeria/SelectorTipoProducto';

export default function Selector() {
  return (
    <Layout>
      <Navbar sinMenu />
      <SelectorTipoProducto />
      <FooterAlt />
    </Layout>
  );
}
